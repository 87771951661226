import React, {useState} from 'react';
import Footer from "../../components/Footer/footer";
import ScrollToTop from "../../components/ScrollToTop";
import AboutSection from "./AboutSection";
import {AboutData} from "./Data";
import Sidebar from "../../components/SideBar/sidebar";
import Navbar from "../../components/Navbar/navbar";

const About = () => {
    const [isOpen, setIsOpen] = useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    
    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} transparent={false}/>
            
            <AboutSection {...AboutData} />
            
            <Footer />
        </>
    )
}

export default About;
