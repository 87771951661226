import React from 'react'
import {MdCall, MdEmail} from "react-icons/all";
import {
    InfoContainer,
    InfoRow,
    InfoWrapper,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    InfoP,
    ImgWrap,
    Img,
} from "../InfoSection/InfoElements";
import {ContactLink, TextCol1, TextCol2, TextRow} from "./ContactElements";

const ContactSection = ({id, lightsection, imgStart, headline, description, description2, description3, img, alt, name,
                            bigSection}) => {

    return (
        <>
            <InfoContainer lightsection={lightsection} id={id} name={name}>
                <InfoWrapper bigSection={bigSection}>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <Heading lightsection={lightsection}>{headline}</Heading>

                                <InfoP lightsection={lightsection}>{description}</InfoP>
    
                                <TextRow>
                                    <TextCol1>
                                        <MdCall color='#101522' fontSize='22px'/>
                                    </TextCol1>
                                    <TextCol2>
                                        <ContactLink href="tel:+31633782484">{description3}</ContactLink>
                                    </TextCol2>
                                </TextRow>
                                <TextRow>
                                    <TextCol1>
                                        <MdEmail color='#101522' fontSize='22px'/>
                                    </TextCol1>
                                    <TextCol2>
                                        <ContactLink href="mailto:info@medology.nl">{description2}</ContactLink>
                                    </TextCol2>
                                </TextRow>

                            </TextWrapper>
                        </Column1>

                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>

                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default ContactSection
