import React, {useState} from 'react';
import Video from '../../../videos/abstract720p.mp4';
import { Button } from '../../../components/ButtonElements';
import {
    TopBg,
    TopContainer,
    VideoBg,
    TopBtnWrapper,
    TopContent,
    TopH1,
    TopP,
    ArrowDownward, ArrowDown
} from "./TopElements";

const TopSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

    return (
        <TopContainer id='home' name='home'>
            <TopBg>
                <VideoBg autoPlay loop muted playsInline autobuffer src={Video} type="video/mp4" preload="auto" />
            </TopBg>
            <TopContent>
                <TopH1>
                    Ontwikkelen van medische mobiele apps
                </TopH1>
                <TopP>
                    Behandelaar en patiënt dichter bij elkaar door innovatieve applicaties, waar gebruikersvriendelijkheid centraal staat.
                </TopP>
                <TopBtnWrapper>
                    <Button to="about"
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            lightsection={0}
                            offset={-80}>
                        Wie zijn wij? { hover ? <ArrowDownward/> : <ArrowDown /> }
                    </Button>
                </TopBtnWrapper>
            </TopContent>
        </TopContainer>
    )
}

export default TopSection
