export const DisclaimerData = {
    id: 'disclaimer',
    name: 'disclaimer',
    lightsection: true,
    headline: 'Disclaimer',
    description: 'Op het gebruik van deze website (www.medology.nl) zijn onderstaande gebruiksvoorwaarden van toepassing. Door gebruik te maken van deze website, wordt u geacht kennis te hebben genomen van de gebruikersvoorwaarden en deze te hebben aanvaard.',
    heading1: 'Gebruik van informatie',
    description2: 'Medology streeft ernaar op deze website altijd juiste en actuele informatie aan te bieden. Hoewel deze informatie met de grootst mogelijke zorgvuldigheid is samengesteld, staat Medology niet in voor de volledigheid, juistheid, of actualiteit van de informatie. De juridische informatie op de website is van algemene aard en kan niet worden beschouwd als een vervanger van juridisch advies.',
    description3: 'Aan de informatie kunnen geen rechten worden ontleend. Medology aanvaardt geen aansprakelijkheid voor schade die voortvloeit uit het gebruik van de informatie op de website en even min voor het niet goed functioneren van de website. Op basis van het verzenden en ontvangen van informatie via de website of via e-mail kan niet zonder meer een relatie tussen Medology en de gebruiker van de website ontstaan.',
    heading2: 'E-mail',
    description4: 'Medology garandeert niet dat aan haar gezonden e-mails (tijdig) worden ontvangen of verwerkt, omdat tijdig ontvangst van e-mails niet kan worden gegarandeerd. Ook de veiligheid van een e-mail verkeer kan niet volledig worden gevangen duurt door de hieraan verbonden veiligheid risico’s. Door zonder encryptie of wachtwoord beveiliging per e-mail met Medology te corresponderen, accepteert u dit risico. ',
    heading3: 'Hyperlinks',
    description5: 'Deze website kan hyperlinks bevatten naar website van derden. Medology heeft geen invloed op de websites van derden en is niet verantwoordelijk voor de beschikbaarheid op inhoud daarvan. Medology aanvaardt dan ook geen aansprakelijkheid voor de schade die voortvloeit uit het gebruik van websites van derden.',
    heading4: 'Intellectuele eigendomsrechten',
    description6: 'Alle publicaties en uitingen van Medology zijn beschermd door auteursrecht en andere intellectuele eigendomsrechten. Behalve voor persoonlijk en niet commercieel gebruik, mag niet uit deze publicatie en uitingen op welke manier dan ook verveelvoudigd, gekopieerd of op andere manier openbaar gemaakt worden, zonder dat Medology daar vooraf schriftelijk toestemming voor heeft gegeven.',
}
