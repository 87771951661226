export const AboutData = {
    id: 'about',
    name: 'about',
    lightsection: true,
    buttonArrowDirectionDown: true,
    img: require('../../images/meeting.svg').default,
    alt: 'about',
    to: 'services',
    headline: 'Over ons',
    description: 'Medology is ontstaan uit een unieke combinatie van medische en technische kennis, samen met de' +
        ' gedachte dat gebruikersvriendelijkheid een van de belangrijkste aspecten van technologische innovatie bij' +
        ' mobiele applicaties is, zeker in de zorg. Om dit te bereiken, is goede communicatie tussen gebruiker' +
        ' en' +
        ' ontwikkelaar essentieel. Doordat de ontwikkelaar begrijpt wat belangrijk is voor de gebruiker, up-to-date' +
        ' blijft met de huidige technologische mogelijkheden en flexibel is in de ontwikkeling van de applicatie,' +
        ' kan het optimale product ontwikkeld worden.',
    description2: 'Naast het innoveren van de medische sector op het gebied van mobiele applicaties door eigen idee,' +
        ' ontwikkelen wij ook applicaties voor klanten in de zorg. Samen met de klant denken wij mee over hoe het' +
        ' idee het beste gerealiseerd kan worden. User experience staat bij ons bovenaan en wij zijn pas tevreden als' +
        ' de eindgebruiker tevreden is over de applicatie.',
    description3: 'Privacy en transparantie met betrekking tot data van gebruikers vinden wij belangrijk, waardoor' +
        ' wij er altijd voor zorgen dat de applicaties aan de huidige en geldende privacywetgeving voldoen.' +
        ' Naast user experience krijgt bescherming van gegevens de hoogste prioriteit en de gebruiker houdt' +
        ' altijd controle en inzicht over eigen data.',
    buttonLabel: 'Wat doen wij?',
    teamDescr1: 'De afgelopen jaren heeft Frank als arts gewerkt bij verschillende instanties, zowel binnen als buiten het ziekenhuis. Hierbij heeft hij ervaren dat door toegenomen administratielast en werkdruk er helaas steeds minder tijd beschikbaar blijft voor het daadwerkelijke “dokteren.” Er kan veel tijd teruggewonnen worden door inzet van de modernste technologieën om de zorg efficiënter en gebruikersvriendelijker te maken. Deze visie en zijn hart voor de zorg zijn de motivatie achter de oprichting van Medology.',
    teamDescr2: 'Angelo heeft zowel ervaring in de gezondheidszorg als in de technische sector. Hij heeft in het' +
        ' verleden geneeskunde gestudeerd en is daarna opgeleid tot software engineer. De combinatie van deze kennis' +
        ' en ervaring zorgt voor een unieke skillset bij het ontwikkelen van medische applicaties.',
    teamDescr3: 'Als gepensioneerde kan hij met zijn jarenlange  ervaring als projectmanager, directeur en bedrijfsvoerder adviseren bij de niet technische aspecten van het bedrijf.',
    teamImg1: require('../../images/frank.jpg').default,
    teamImgAlt1: 'Frank W. de Jongh',
    teamImg2: require('../../images/angelo.jpg').default,
    teamImgAlt2: 'Angelo Jansen',
    teamImg3: require('../../images/willem.png').default,
    teamImgAlt3: 'Willem P.E. de Jongh',
}

