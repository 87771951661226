export const ServicesData = {
    id: 'services',
    serviceHeading1: 'Consultancy',
    serviceText1: 'Heb je een idee wat je graag ontwikkeld wil hebben in een smartdevice applicatie? Wij denken' +
        ' mee en realiseren de app.',
    serviceImg1: require('../../../images/idea.svg').default,
    serviceHeading2: 'App ontwikkeling',
    serviceText2: 'Vanuit ervaring en betrokkenheid in de medische wereld, ontwikkelen wij innovatieve' +
        ' mobiele applicaties.',
    serviceImg2: require('../../../images/app.svg').default,
}

