import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.footer`
    background-color: #101522;
`

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    
    @media screen and (max-width: 896px) {
        align-items: flex-start;
        margin: 24px auto 0 30px;
    }
`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 896px) {
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;
    
    @media screen and (max-width: 576px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
`

export const FooterText = styled.p`
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
`

export const FooterA = styled.a`
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0.5rem;
  
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #00d6ba;
        transition: all 0.1s ease-in-out;
      }
    }
    
    &:active {
      transition: all 0.1s ease-in-out;
      color: #00d6ba;
    }
`

export const FooterLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #00d6ba;
      transition: all 0.1s ease-in-out;
    }
  }

  &:active {
    transition: all 0.1s ease-in-out;
    color: #00d6ba;
  }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;
    
    @media screen and (max-width: 896px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 40px auto 0 10px;
    }
`

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
`

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
`

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
    padding: 0 0 0 10px;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #00d6ba;
            transition: all 0.1s ease-in-out;
        }
    }
    
    &:active {
        transition: all 0.1s ease-in-out;
        color: #00d6ba;
    }
`

