import React, {useState} from 'react'
import {
    ServicesContainer,
    ServicesCard,
    ServicesH1,
    ServicesH2,
    ServicesIcon,
    ServicesP,
    ServicesWrapper
} from "./ServicesElements";
import {ArrowDown, ArrowDownward, TopBtnWrapper} from "../TopSection/TopElements";
import {Button} from "../../../components/ButtonElements";

const ServicesSection = ({id,serviceHeading1,serviceText1,serviceImg1,serviceHeading2,serviceText2,
                         serviceImg2}) => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }
    return (
        <ServicesContainer id={id}>
            <ServicesH1>Onze Diensten</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={serviceImg1}/>
                    <ServicesH2>{serviceHeading1}</ServicesH2>
                    <ServicesP>{serviceText1}</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={serviceImg2}/>
                    <ServicesH2>{serviceHeading2}</ServicesH2>
                    <ServicesP>{serviceText2}</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
            <TopBtnWrapper>
                <Button to="contact"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        lightsection={0}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}>
                    Interesse? { hover ? <ArrowDownward/> : <ArrowDown /> }
                </Button>
            </TopBtnWrapper>
        </ServicesContainer>
    )
}

export default ServicesSection
