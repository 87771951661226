import React, {useState} from 'react'
import { Button } from '../../../components/ButtonElements'
import {ArrowDown, ArrowDownward, ArrowForward, ArrowRight} from "../TopSection/TopElements";
import {
    InfoContainer,
    InfoRow,
    InfoWrapper,
    Column1,
    Column2,
    TextWrapper,
    Heading,
    InfoP,
    BtnWrap,
    ImgWrap,
    Img, InfoLink,
} from "./InfoElements";

const InfoSection = ({id, lightsection, imgStart, headline, description, description2, description3, buttonLabel, img,
                         alt, name, to, bigSection, button, buttonArrowDirectionDown}) => {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <>
            <InfoContainer lightsection={lightsection} id={id} name={name}>
                <InfoWrapper bigSection={bigSection}>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <Heading lightsection={lightsection}>{headline}</Heading>
                                <InfoP lightsection={lightsection}>{description}</InfoP>
                                <InfoP lightsection={lightsection}>{description2}</InfoP>
                                <InfoP lightsection={lightsection}>{description3}</InfoP>
                                <InfoLink to="/about">Lees verder »</InfoLink>
                                {button
                                    ? <BtnWrap>
                                        <Button
                                            onMouseEnter={onHover}
                                            onMouseLeave={onHover}
                                            to={to}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact='true'
                                            offset={-80}
                                            lightsection={lightsection ? 1 : 0}
                                            big='false'
                                        >
                                            {buttonLabel}{
                                                buttonArrowDirectionDown
                                                    ? hover ? <ArrowDownward/> : <ArrowDown />
                                                    : hover ? <ArrowForward/> : <ArrowRight />
                                            }
                                        </Button>
                                    </BtnWrap>
                                    : <div/>
                                }
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
