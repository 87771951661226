import styled from 'styled-components'
import {Link} from 'react-scroll'

export const Button = styled(Link)`
    border-radius: 30px;
    background: ${({lightsection}) => (lightsection ? '#101522' : '#00d6ba')};
    white-space: nowrap;
    margin-top: 40px;
    color: ${({lightsection}) => (lightsection ? '#fff' : '#101522')};
    padding: 12px 26px;
    font-size: 1.1rem;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({lightsection}) => (lightsection ? '#00d6ba' : '#fff')};
        transform: scale(1.01);
    }
  
    @media screen and (max-width: 768px) {
        font-weight: 500;
        font-size: 1.0rem;
    }

    @media screen and (max-width: 576px) {
        font-weight: 500;
        font-size: 0.9rem;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 1000px) {
        font-weight: 500;
        font-size: 0.9rem;
    }
  
`;
