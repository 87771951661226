import React, {useState} from 'react';
import {DisclaimerData} from "./Data";
import Footer from "../../components/Footer/footer";
import ScrollToTop from "../../components/ScrollToTop";
import DisclaimerSection from "./index";
import Navbar from "../../components/Navbar/navbar";
import Sidebar from "../../components/SideBar/sidebar";

const Disclaimer = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>

            <DisclaimerSection {...DisclaimerData} />

            <Footer />
        </>
    )
}

export default Disclaimer;
