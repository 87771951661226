import styled from 'styled-components'

export const AboutContainer = styled.div`
    color: #fff;
    background: ${({lightsection}) => (lightsection ? '#f6f6f6' : '#101522')};
    padding-top: 170px;
    padding-bottom: 70px;
`

export const AboutWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const AboutRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: flex-start;
    justify-items: center;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas:
        'intro intro'
        'col1 col2'
        'col1 col2'
        'col3 col4'
        'col3 col4';
    
    @media screen and (max-width: 768px) {
      grid-template-areas:
        'intro intro'
        'col1 col1'
        'col2 col2'
        'col3 col3'
        'col4 col4';
    }
`

export const AboutColIntroWrap = styled.div`
  margin-bottom: 10px;
  padding: 0 15px;
  grid-area: intro;
`

export const AboutColIntroP = styled.p`
    color: ${({lightsection}) => (lightsection ? '#101522' : '#fff')};
    margin-bottom: 20px;
    font-size: 0.9rem;
    line-height: 24px;
  
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 576px) {
      font-size: 0.8rem;
    }
`

export const AboutCol1 = styled.div`
    margin-bottom: 10px;
    padding: 0 15px 0 15px;
    max-width: 450px;
    grid-area: col1;
`

export const AboutCol2 = styled.div`
    margin-bottom: 10px;
    padding: 0 15px 0 15px;
    max-width: 450px;
    grid-area: col2;
`

export const AboutCol3 = styled.div`
    margin-bottom: 10px;
    padding: 0 15px 0 15px;
    max-width: 450px;
    grid-area: col3;
`

export const AboutTextWrapper = styled.div`
    max-width: 540px;
    padding-bottom: 30px;
  
    @media screen and (max-width: 480px) {
      padding-bottom: 15px;
    }
`

export const AboutH1 = styled.h1`
    margin-bottom: 24px;
    font-size: 2.4rem;
    line-height: 1.1;
    font-weight: 700;
    color: ${({lightsection}) => (lightsection ? '#101522' : '#f7f8fa')};
    
    @media screen and (max-width: 768px) {
        font-size: 2.0rem;
    }
    
    @media screen and (max-width: 576px) {
        font-size: 1.8rem;
    }
`

export const AboutP = styled.p`
    //max-width: auto;
    margin-bottom: 20px;
    font-size: 0.9rem;
    line-height: 24px;
    color: ${({lightsection}) => (lightsection ? '#101522' : '#fff')};

    @media screen and (max-width: 768px) {
        font-size: 0.9rem;
    }
    
    @media screen and (max-width: 576px) {
        font-size: 0.8rem;
    }
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ProfileImgWrap = styled.div`
    height: 100%;
    text-align: center;
`

export const ProfileImg = styled.img`
    width: 100%;
    max-width: 150px;
    margin: 50px auto 30px auto;
    display: block;
    padding-right: 0;
    border-radius: 50%;
    
    @media screen and (max-width: 480px) {
      margin: 30px auto 30px auto;
    }
`

export const ProfileImgTextWrap = styled.div`
    margin-bottom: 30px;
    display: grid;
    grid-template-areas: 'null text text icon';
    grid-template-columns: auto 49% 49% 1%;
    grid-template-rows: auto;
    justify-items: center;
`

export const ProfileImgText = styled.p`
    grid-area: text;
    color: #101522;
    font-weight: 500;
    font-size: 16px;
`

export const ProfileSocialIcon = styled.a`
  grid-area: icon;
  color: #101522;
  font-size: 24px;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #00d6ba;
      transition: all 0.1s ease-in-out;
    }
  }

  &:active {
    transition: all 0.1s ease-in-out;
    color: #00d6ba;
  }
`
