export const PrivacyData = {
    id: 'privacy',
    title: 'Data & Privacy',
    p1: 'Onze producten moeten voldoen aan de veiligheidsnormen en kwaliteitseisen die zijn gesteld binnen' +
        ' Nederland en de Europese' +
        ' Unie. Wij dragen zorg voor het volledig en tijdig implementeren van onderstaande Europese wetten en' +
        ' standaarden, waardoor veiligheid en transparantie van data wordt gewaarborgd.',
    
    h2: 'Europese Unie',
    p2: 'Binnen de EU geldt sinds 25 mei 2018 de AVG (Algemene Verordening Gegevensbescherming, engels: GDPR).' +
        ' Daarnaast geldt 26 mei 2021 de MDR (Medical Device Regulation). Voor' +
        ' medische software applicaties is het ook verplicht is een CE-markering te dragen.',
    h3: 'NEN',
    p3: 'Naast de Europese regelgeving, zijn er voor producten binnen Nederland normen gesteld door de Stichting' +
        ' Koninklijk' +
        ' Nederlands Normalisatie Instituut. Wij houden ons aan de normen: NEN 7510,' +
        ' 7512 en 7513, waarmee we de zorgspecifieke informatievoorziening en beveiliging instellen en handhaven. In' +
        ' deze normen van de NEN zijn de internationale ISO 27001, 27002 en 27799 normen verwerkt.',
}
