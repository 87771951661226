import styled from 'styled-components'
import {MdArrowDownward, MdArrowForward, MdKeyboardArrowDown, MdKeyboardArrowRight} from "react-icons/md";

export const TopContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
    
    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.6) 100%
            ),
            linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
        z-index: 2;
    }
  
`;

export const TopBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const TopContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;    
`

export const TopH1 = styled.h1`
    color: #fff;
    font-size: 48px;
    text-align: center;
    
    @media screen and (max-width: 768px) {
        font-size: 40px;
    }
    
    @media screen and (max-width: 576px) {
        font-size: 32px;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        font-size: 32px;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 896px) {
        font-size: 26px;
    }
`

export const TopP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    max-width: 700px;
    
    @media screen and (max-width: 768px) {
        font-size: 1.2rem;
    }
    
    @media screen and (max-width: 576px) {
        font-size: 1.0rem;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        font-size: 1.0rem;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 896px) {
        font-size: 0.9rem;
    }
`

export const TopBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        margin-top: 10px;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 896px) {
        margin-top: 0;
    }
`

export const ArrowDownward = styled(MdArrowDownward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowDown = styled(MdKeyboardArrowDown)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`






