import React from 'react'
import {
    DiscColumn,
    DiscContainer,
    DiscH1,
    DiscH2,
    DiscP,
    DiscRow,
    DiscTextWrapper,
    DiscWrapper
} from "./DisclaimerElements";

const DisclaimerSection = ({id, lightsection, headline, description, description2, description3, description4, description5, description6, heading1, heading2, heading3, heading4, name}) => {

    return (
        <>
            <DiscContainer lightsection={lightsection} id={id} name={name}>
                <DiscWrapper>
                    <DiscRow>
                        <DiscColumn>
                            <DiscTextWrapper>
                                <DiscH1 lightsection={lightsection}>{headline}</DiscH1>
                                <DiscP lightsection={lightsection}>{description}</DiscP>

                                <DiscH2 lightsection={lightsection}>{heading1}</DiscH2>
                                <DiscP lightsection={lightsection}>{description2}</DiscP>
                                <DiscP lightsection={lightsection}>{description3}</DiscP>

                                <DiscH2 lightsection={lightsection}>{heading2}</DiscH2>
                                <DiscP lightsection={lightsection}>{description4}</DiscP>

                                <DiscH2 lightsection={lightsection}>{heading3}</DiscH2>
                                <DiscP lightsection={lightsection}>{description5}</DiscP>

                                <DiscH2 lightsection={lightsection}>{heading4}</DiscH2>
                                <DiscP lightsection={lightsection}>{description6}</DiscP>

                            </DiscTextWrapper>
                        </DiscColumn>
                    </DiscRow>
                </DiscWrapper>
            </DiscContainer>
        </>
    )
}

export default DisclaimerSection
