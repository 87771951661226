import React from 'react'
import {
    AboutContainer,
    AboutWrapper,
    AboutRow,
    AboutCol1,
    AboutCol2,
    AboutTextWrapper,
    AboutH1,
    AboutP,
    ProfileImgWrap,
    ProfileImg,
    AboutColIntroWrap,
    AboutColIntroP,
    ProfileImgText,
    ProfileSocialIcon,
    ProfileImgTextWrap, AboutCol3,
} from "./AboutElements";
import {FaLinkedin} from "react-icons/all";

const AboutSection = ({id, lightsection, headline, description, description2, description3, name, bigSection,
                          teamDescr1, teamDescr2, teamDescr3, teamImg1, teamImg2, teamImg3, teamImgAlt1,
                          teamImgAlt2, teamImgAlt3}) => {

    return (
        <>
            <AboutContainer lightsection={lightsection} id={id} name={name}>
                <AboutWrapper bigSection={bigSection}>
                    
                    <AboutRow>
                        <AboutColIntroWrap>
                            <AboutH1 lightsection={lightsection}>{headline}</AboutH1>
                            <AboutColIntroP lightsection={lightsection}>{description}</AboutColIntroP>
                            <AboutColIntroP lightsection={lightsection}>{description2}</AboutColIntroP>
                            <AboutColIntroP lightsection={lightsection}>{description3}</AboutColIntroP>
                        </AboutColIntroWrap>
                    </AboutRow>
                    
                    <AboutRow>
                        <AboutCol1>
                            <AboutTextWrapper>
                                <ProfileImgWrap>
                                    <ProfileImg src={teamImg1} alt={teamImgAlt1} />
                                    <ProfileImgTextWrap>
                                        <ProfileImgText>drs. Frank W. de Jongh</ProfileImgText>
                                        <ProfileSocialIcon href="//www.linkedin.com/in/frank-willem-de-jongh-09146736">
                                            <FaLinkedin />
                                        </ProfileSocialIcon>
                                    </ProfileImgTextWrap>
                                </ProfileImgWrap>
                                <AboutP lightsection={lightsection}>{teamDescr1}</AboutP>
                            </AboutTextWrapper>
                        </AboutCol1>
                        <AboutCol2>
                            <ProfileImgWrap>
                                <ProfileImg src={teamImg2} alt={teamImgAlt2} />
                                <ProfileImgTextWrap>
                                    <ProfileImgText>Angelo Jansen</ProfileImgText>
                                    <ProfileSocialIcon href="//www.linkedin.com/in/angelo530/">
                                        <FaLinkedin />
                                    </ProfileSocialIcon>
                            </ProfileImgTextWrap>
                            </ProfileImgWrap>
                            <AboutTextWrapper>
                                <AboutP lightsection={lightsection}>{teamDescr2}</AboutP>
                            </AboutTextWrapper>
                        </AboutCol2>
                        <AboutCol3>
                            <AboutTextWrapper>
                                <ProfileImgWrap>
                                    <ProfileImg src={teamImg3} alt={teamImgAlt3} />
                                    <ProfileImgTextWrap>
                                        <ProfileImgText>ing. Willem P.E. de Jongh</ProfileImgText>
                                        <ProfileSocialIcon href="//www.linkedin.com/in/wim-de-jongh-4687bb18/">
                                            <FaLinkedin />
                                        </ProfileSocialIcon>
                                    </ProfileImgTextWrap>
                                </ProfileImgWrap>
                                <AboutP lightsection={lightsection}>{teamDescr3}</AboutP>
                            </AboutTextWrapper>
                        </AboutCol3>
                    </AboutRow>
                    
                </AboutWrapper>
            </AboutContainer>
        </>
    )
}

export default AboutSection
