import styled from 'styled-components'


export const TextRow = styled.div`
    display: grid;
    padding: 0 0 20px 0;
    //grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';
    grid-template-columns: 50px 250px;
    grid-template-rows: 25px 25px;
`

export const TextCol1 = styled.div`
    grid-area: col1;
`

export const TextCol2 = styled.div`
    grid-area: col2;
`

export const ContactLink = styled.a`
    font-size: 1.1rem;
    line-height: 24px;
    color: #101522;
    cursor: pointer;
    text-decoration: none;


    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #00d6ba;
            transition: all 0.2s ease-in-out;
        }
    }
    
    &:active {
        transition: all 0.1s ease-in-out;
        color: #00d6ba;
    }
  
    @media screen and (max-width: 768px) {
        font-size: 1.0rem;
    }

    @media screen and (max-width: 576px) {
        font-size: 0.9rem;
    }
    
    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
      font-size: 0.9rem;
    }
`
