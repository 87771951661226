import React from 'react'
import {
    PrivacyColumn,
    PrivacyContainer,
    PrivacyH1, PrivacyH2, PrivacyP,
    PrivacyRow,
    PrivacyTextWrapper,
    PrivacyWrapper
} from "./PrivacyElements";

const PrivacySection = ({title, p1, h2, p2, h3, p3, p4}) => {
    
    return (
        <>
            <PrivacyContainer>
                <PrivacyWrapper>
                    <PrivacyRow>
                        <PrivacyColumn>
                            <PrivacyTextWrapper>
                                <PrivacyH1>{title}</PrivacyH1>
                                <PrivacyP>{p1}</PrivacyP>
                                
                                <PrivacyH2>{h2}</PrivacyH2>
                                <PrivacyP>{p2}</PrivacyP>
    
                                <PrivacyH2>{h3}</PrivacyH2>
                                <PrivacyP>{p3}</PrivacyP>
                                
                                <PrivacyP>{p4}</PrivacyP>
                                
                            </PrivacyTextWrapper>
                        </PrivacyColumn>
                    </PrivacyRow>
                </PrivacyWrapper>
            </PrivacyContainer>
        </>
    )
}

export default PrivacySection
