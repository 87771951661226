import React from 'react'
import { FaLinkedin, FaInstagram } from "react-icons/all";
import {
    FooterContainer,
    FooterLink,
    FooterLinkItems,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkTitle,
    FooterText,
    FooterWrap,
    SocialIconLink,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights, FooterA
} from "./FooterElements";

const Footer = () => {

    return(
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contact</FooterLinkTitle>
                            <FooterText>Medology V.O.F.</FooterText>
                            <FooterA href="tel:+31633782484">+31 6 33 78 24 84</FooterA>
                            <FooterA  href="mailto:info@medology.nl">info@medology.nl</FooterA>
                            <FooterA href="//www.kvk.nl/zoeken/?source=all&q=80182968&start=0&site=kvk2014" target="_blank">KvK: 80182968</FooterA>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Navigatie</FooterLinkTitle>
                            <FooterLink to='/'>Home</FooterLink>
                            <FooterLink to='/about'>Over Ons</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Overig</FooterLinkTitle>
                            <FooterLink to='/disclaimer'>Disclaimer</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                                <SocialIconLink href='//www.linkedin.com/company/71232576' target="_blank" aria-label='Linkedin'>
                                    <FaLinkedin />
                                </SocialIconLink>
                                <SocialIconLink href='//www.instagram.com/medology.apps/' target="_blank" aria-label='Instagram'>
                                    <FaInstagram />
                                </SocialIconLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <WebsiteRights>© Medology {new Date().getFullYear()}</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
