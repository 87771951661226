import styled from 'styled-components'
import {Link as LinkR} from "react-router-dom";

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightsection}) => (lightsection ? '#f6f6f6' : '#101522')};
  
  @media screen and (max-width: 768px) {
        //padding: 75px 0;
    }
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 95vh;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    
    @media screen and (max-width: 768px) {
        height: auto;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        height: auto;
    }
`

export const InfoRow = styled.div`
    margin-top: 64px;
    margin-bottom: 64px;
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    justify-items: center;
    grid-gap: 50px;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    
    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
        margin-top: 100px;
    }
  
    @media screen and (orientation:landscape)
        and (max-device-width: 1024px) {
            height: auto;
            margin-top: 100px;
        }
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    margin-right: 30px;
    margin-left: 30px;
    padding: 0 15px;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-bottom: 60px;
`

export const Heading = styled.h1`
    margin-bottom: 40px;
    font-size: 2.5rem;
    line-height: 1.1;
    font-weight: 700;
    color: ${({lightsection}) => (lightsection ? '#101522' : '#f7f8fa')};

    @media screen and (max-width: 768px) {
        font-size: 2.0rem;
    }
  
    @media screen and (max-width: 576px) {
        font-size: 1.8rem;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
      font-size: 1.8rem;
    }
`

export const InfoP = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 1.1rem;
    line-height: 24px;
    color: ${({lightsection}) => (lightsection ? '#101522' : '#fff')};

    @media screen and (max-width: 768px) {
        font-size: 1.0rem;
    }

    @media screen and (max-width: 576px) {
        font-size: 0.9rem;
    }

  @media screen and (orientation:landscape)
  and (max-device-width: 1024px) {
    font-size: 0.9rem;
  }
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    max-width: 400px;

    @media screen and (max-width: 768px) {
        max-width: 350px;
        margin: 0;
    }
    
    @media screen and (max-width: 576px) {
        margin: 0;
    }
    
    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        margin: 0;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 896px) {
        margin: 0;
        max-width: 250px;
    }
  
`

export const InfoLink = styled(LinkR)`
    font-size: 1.1rem;
    line-height: 24px;
    color: #101522;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 0.1px solid #101522;
    padding-bottom: 1px;
  
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #00d6ba;
            transition: all 0.2s ease-in-out;
            border-bottom: 0.1px solid #00d6ba;
        }
    }
    
    &:active {
        transition: all 0.1s ease-in-out;
        color: #00d6ba;
        border-bottom: 0.1px solid #00d6ba;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.0rem;
    }
    
    @media screen and (max-width: 576px) {
       font-size: 0.9rem;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        font-size: 0.9rem;
    }
`
