import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from "./pages/Home/home";
import Disclaimer from "./pages/Disclaimer/disclaimer";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";

function App() {
  return (
    <Router>
        <Switch>
            <Route path='/' component={Home} exact />
            <Route path='/about' component={About} exact />
            <Route path='/disclaimer' component={Disclaimer} exact />
            <Route path='/privacy' component={Privacy} exact />
            {/*<Route path='/contact' component={Contact} exact />*/}

        </Switch>
    </Router>
  );
}

export default App;
