import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #101522;
    display: grid;
    align-items: center;
    top: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')}; 
`;

export const CloseIcon = styled(FaTimes)`
    color: #fff;
  
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #00d6ba;
            transition: all 0.1s ease-in-out;
            transform: scale(1.1);
        }
    }
    
    &:active {
        transition: all 0.1s ease-in-out;
        color: #00d6ba;
        transform: scale(1.1);
    }
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const SidebarWrapper = styled.div`
    color: #fff;
`;


export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
    
    @media screen and (max-width: 576px) {
        grid-template-rows: repeat(6, 60px);
    }
`;

export const SidebarDivider = styled.hr`
    width: 30%;
    color: #fff;
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;
    
    &:hover {
        color: #01bf71;
        transition: 0.2s ease-in-out;
    }
`;

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 0 50px 0;
`;

export const SidebarRoute = styled(LinkR)`
    display: flex;
    align-items: center;
    padding: 0 0 0 0;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #00d6ba;
            transition: all 0.1s ease-in-out;
        }
    }
    
    &:active {
        transition: all 0.1s ease-in-out;
        color: #00d6ba;
    }
`;
