export const ContactData = {
    id: 'contact',
    name: 'contact',
    lightsection: true,
    button: false,
    imgStart: true,
    img: require('../../../images/contact.svg').default,
    alt: 'contact',
    to: 'contact',
    headline: 'Wat kunnen we voor je doen?',
    description: 'Neem contact met ons op om de mogelijkheiden te bespreken!',
    description2: 'info@medology.nl',
    description3: '+31 6 33 78 24 84',
    buttonLabel: 'Stuur ons een email!',
}
