export const AboutData = {
    id: 'about',
    name: 'about',
    lightsection: true,
    button: true,
    buttonArrowDirectionDown: true,
    imgStart: false,
    img: require('../../../images/doctors.svg').default,
    alt: 'about',
    to: 'services',
    headline: 'Wie zijn wij?',
    description: 'Vanuit ervaring in de medische en technische wereld combineren wij onze kennis om gebruikersvriendelijke applicaties voor de zorg te ontwikkelen.',
    description2: 'Onze missie is het dichter bij elkaar brengen van patiënt en behandelaar door de nieuwste technologieën op het gebied van smartdevice applicaties te implementeren.',
    buttonLabel: 'Wat doen wij?',
}

