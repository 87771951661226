import React, {useEffect, useState} from 'react';
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from 'react-scroll'
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem, NavImgWrap, NavImg, NavRoute, HamburgerIcon
} from "./NavbarElements";

const Navbar = ({ toggle, transparent }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 100) {
            setScrollNav(true)
        }
        else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
            <Nav scrollNav={scrollNav} transparent={transparent}>
                <NavbarContainer>
                    <NavImgWrap>
                        <NavLogo to="/" onClick={toggleHome}>
                            <NavImg src={require('../../images/logo.png').default} alt="a"/>
                        </NavLogo>
                    </NavImgWrap>
                    <MobileIcon onClick={toggle}>
                        <HamburgerIcon />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavRoute to='/about'>
                                Over ons
                            </NavRoute>
                        </NavItem>
                        <NavItem>
                            <NavRoute to='/privacy'>
                                Data & Privacy
                            </NavRoute>
                        </NavItem>
                        {/*<NavItem>*/}
                        {/*    <NavRoute to='/contact'>*/}
                        {/*        Contact*/}
                        {/*    </NavRoute>*/}
                        {/*</NavItem>*/}
                    </NavMenu>
                </NavbarContainer>
            </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar;
