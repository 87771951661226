import styled from 'styled-components'
import {Link} from "react-router-dom";

export const ServicesContainer = styled.div`
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #101522;
    //margin-top: 64px;
    padding-top: 64px;
    margin-bottom: 64px;
    
    @media screen and (max-width: 768px) {
        height: auto;
        margin-top: 100px;
        margin-bottom: 100px;
    }

    @media screen and (orientation:landscape)
        and (max-device-width: 1024px) {
            height: auto;
            margin-top: 100px;
            margin-bottom: 100px;
        }
`

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
    padding: 0 50px;
    
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;    
        padding: 0 20px;
    }
`

export const ServicesCard = styled.div`
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    max-height: 250px;
    padding: 30px;
    margin: 5px 10px 0 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    
    //&:hover {
        //transform: scale(1.02);
        //transition: all 0.2s ease-in-out;
        //cursor: pointer;
    //}

    @media screen and (max-width: 768px) {
        max-height: 340px;
        max-width: 500px;
    }

    @media screen and (max-width: 576px) {
        max-height: 340px;
    }

    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        height: 250px;
        max-width: 400px;
    }
`

export const ServicesIcon = styled.img`
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;


    @media screen and (max-width: 768px) {
        font-size: 2.3rem;
    }
    
    @media screen and (max-width: 576px) {
        font-size: 2.0rem;
    }
    
    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        font-size: 2.0rem;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 1.0rem;
    margin-bottom: 10px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 0.9rem;
    }
    
    @media screen and (max-width: 576px) {
        font-size: 0.8rem;
    }
    
    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        font-size: 0.8rem;
    }
`

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;

    @media screen and (max-width: 768px) {
       font-size: 0.9rem;
    }
    
    @media screen and (max-width: 576px) {
       font-size: 0.8rem;
    }
    
    @media screen and (orientation:landscape)
    and (max-device-width: 1024px) {
        font-size: 0.8rem;
    }
`

export const ServicesLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    // font-size: 14px;
    //
    // &:hover {
    //     color: #01bf71;
    //     transition: 0.3s ease-out;
    // }
`





