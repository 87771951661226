import React, {useState} from 'react';
import Sidebar from "../../components/SideBar/sidebar";
import Navbar from "../../components/Navbar/navbar";
import ScrollToTop from "../../components/ScrollToTop";
import PrivacySection from "./PrivacySection";
import {PrivacyData} from "./Data";
import Footer from "../../components/Footer/footer";


const Privacy = () => {
    const [isOpen, setIsOpen] = useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    
    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} transparent={false}/>
            
            <PrivacySection {...PrivacyData} />
            
            <Footer />
        </>
    )
}

export default Privacy;
