import React, {useState} from 'react';
import Sidebar from "../../components/SideBar/sidebar";
import Navbar from "../../components/Navbar/navbar";
import TopSection from "./TopSection";
import ServicesSection from "./ServicesSection";
import {ContactData} from "./Contact/Data";
import Footer from "../../components/Footer/footer";
import ScrollToTop from "../../components/ScrollToTop";
import ContactSection from "./Contact";
import InfoSection from "./InfoSection";
import {AboutData} from "./InfoSection/Data";
import {ServicesData} from "./ServicesSection/Data";


const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <ScrollToTop />
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} transparent={true}/>

            <TopSection />
            <InfoSection {...AboutData} />
            <ServicesSection {...ServicesData}/>
            <ContactSection {...ContactData} />

            <Footer />
        </>
    )
}

export default Home;
