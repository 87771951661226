import React from 'react';
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SideBtnWrap, SidebarRoute, SidebarDivider
} from "./SidebarElements";

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                
                
                <SidebarMenu>
                    <SidebarRoute to='/'>Home</SidebarRoute>
                    <SidebarRoute to='/about'>Over ons</SidebarRoute>
                    <SidebarRoute to='/privacy'>Data & Privacy</SidebarRoute>
                    {/*<SidebarRoute to='/contact'>Contact</SidebarRoute>*/}
                </SidebarMenu>

                <SideBtnWrap>
                    <SidebarDivider/>
                </SideBtnWrap>

                <div>
                    <SidebarRoute to='/disclaimer'>Disclaimer</SidebarRoute>
                </div>

            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
