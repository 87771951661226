import styled from "styled-components";

export const DiscContainer = styled.div`
    color: #fff;
    background: ${({lightsection}) => (lightsection ? '#f6f6f6' : '#101522')};
    padding-top: 170px;
`

export const DiscWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const DiscRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
`

export const DiscColumn = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
`

export const DiscTextWrapper = styled.div`
    max-width: 1040px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const DiscH1 = styled.h1`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 1.1;
    font-weight: 700;
    color: ${({lightsection}) => (lightsection ? '#101522' : '#f7f8fa')};
    
    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
`

export const DiscH2 = styled.h2`
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 700;
    color: ${({lightsection}) => (lightsection ? '#101522' : '#f7f8fa')};
    
    @media screen and (max-width: 480px) {
        font-size: 22px;
    }
`

export const DiscP = styled.p`
    max-width: 1000px;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 24px;
    color: ${({lightsection}) => (lightsection ? '#101522' : '#fff')};
`
