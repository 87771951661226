import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
    background: ${({scrollNav, transparent}) => ((scrollNav || !transparent) ? '#040717' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    transition: 0.2s all ease;
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 12px;
    max-width: 1100px;    
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        color: #fff;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.7rem;
        cursor: pointer;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #00d6ba;
          transition: all 0.1s ease-in-out;
        }
    }
    
    &:active {
        transition: all 0.1s ease-in-out;
        color: #00d6ba;
    }
`;

export const HamburgerIcon = styled(FaBars)`
    color: #fff;
  
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #00d6ba;
            transition: all 0.1s ease-in-out;
        }
    }
    
    &:active {
        transition: all 0.1s ease-in-out;
        color: #00d6ba;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLink = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    
    &.active {
        border-bottom: 3px solid #00d6ba;
    }
  
    &:hover {
      transition: all 0.2s ease-in-out;
      color: #00d6ba;
    }
`;

export const NavRoute = styled(LinkR)`
    color: #fff;
    display: flex;
    align-items: center;
    margin-right: 15px;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    border-bottom: ${({currentpage}) => ((currentpage) ? '#3px solid #00d6ba' : 'none')};
  
    &:hover {
      transition: all 0.2s ease-in-out;
      color: #00d6ba;
    }
`;

export const NavImgWrap = styled.div`
    max-width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15px;

    @media screen and (max-width: 768px) {
      max-width: 145px;
    }

    @media screen and (max-width: 576px) {
      max-width: 130px;
    }
`

export const NavImg = styled.img`
    width: 90%;
    margin: 10px 0 10px 0;
    padding-right: 0;   
`
