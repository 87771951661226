import styled from 'styled-components'

export const PrivacyContainer = styled.div`
    color: #fff;
    background: #f6f6f6;
    padding-top: 170px;
    padding-bottom: 70px;
`

export const PrivacyWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const PrivacyRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
`

export const PrivacyColumn = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
`

export const PrivacyTextWrapper = styled.div`
    max-width: 1040px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const PrivacyH1 = styled.h1`
    color: #101522;
    margin-bottom: 24px;
    font-size: 2.4rem;
    line-height: 1.1;
    font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 2.0rem;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.8rem;
  }
`

export const PrivacyH2 = styled.h2`
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 1.1rem;
    line-height: 1.1;
    font-weight: 700;
    color: #101522;
    
    @media screen and (max-width: 480px) {
        font-size: 1.0rem;
    }
`

export const PrivacyP = styled.p`
    max-width: 1000px;
    margin-bottom: 35px;
    font-size: 0.9rem;
    line-height: 24px;
    color: #101522;
  
  @media screen and (max-width: 576px) {
    font-size: 0.8rem;
  }
`
